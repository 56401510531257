module.exports = {
  pathPrefix: `/`,
  url: `https:www.aibrok.com/`,
  title: `AiBrok`,
  titleTemplate: `%s — AiBrok`,
  description: `Hemos puesto a disposición de nuestros clientes un universo de posibilidades para integrar en un único lugar todas sus necesidades `,
  image: `/images/meta-image-default.png`, // Path to the default meta image in `static` folder
  siteLanguage: `es`, // Language tag on <html> element

  // Web App Manifest
  favicon: `src/images/favicon.png`, // Used for manifest favicon generation
  shortName: `Starter Styled`, // shortname for manifest. *Must* be shorter than 12 characters
  themeColor: `#ccc`,
  backgroundColor: `#eee`,

  // schema.org JSONLD
  headline: `Hemos puesto a disposición de nuestros clientes un universo de posibilidades para integrar en un único lugar todas sus necesidades `,
  author: ``,

  // Google & Twitter
  googleAnalyticsId: `UA-XXXXX`, // Google Analytics ID
  twitter: `@Aibrok`, // Twitter username
}
